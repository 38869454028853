import axios from "axios";
import React, { useEffect, useState } from "react";
import URL from "../../../BaseUrl/BaseUrl";
import { FaCartArrowDown } from "react-icons/fa";
import { IoPersonCircle } from "react-icons/io5";
import { MdLocalPharmacy } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdDeliveryDining } from "react-icons/md";
import { Box } from "@mui/material";
import { IoArrowBack } from "react-icons/io5";

function OrderDetails({ selectedOrder, setselectedOrder, setshowDetail }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setdata] = useState(null);
  console.log(data);
  const getData = async () => {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    };
    setIsLoading(true);
    axios
      .get(`${URL}pharmacyOrder/get/${selectedOrder?._id}`, {
        headers: header,
      })
      .then((res) => {
        setIsLoading(false);
        setdata(res.data?.data);
      })
      .catch((err) => {
        setIsLoading(false);
      })
      .finally((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (selectedOrder) {
      getData();
    }
  }, [selectedOrder]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // prevArrow: <SlickLeftArrow2 />,
    // nextArrow: <SlickRightArrow2 />,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // 1 item on small screens
      {
        breakpoint: 485,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // 1 item on small screens
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // 2 items on screens with breakpoint > 500px
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // 3 items on screens with breakpoint > 800px
      {
        breakpoint: 1075,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // 3 items on screens with breakpoint > 800px
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // 3 items on screens with width > 800px
      // Add more breakpoints as needed
    ],
  };
  console.log(data?.images.length);
  return (
    <>
      <h5 style={{ padding: "10px" }}>
        Order Details <FaCartArrowDown />
        {/* <i class="fa-solid fa-people-group "></i> */}
      </h5>
      <Box
        onClick={() => {
          setshowDetail(false);
          setselectedOrder(null);
        }}
        sx={{
          width: "50px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          backgroundColor: "#116cc9",
          cursor: "pointer",
        }}
      >
        <IoArrowBack color="#fff" size={22} />
      </Box>
      <div style={{ display: "flex", gap: "20px", width: "100%" ,marginTop:"20px"}}>
        <div style={{ display: "flex", gap: "20px", width: "50%" }}>
          <div>
            <IoPersonCircle size={50} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <span style={{ fontSize: "20px", fontWeight: "600" }}>
              User Details
            </span>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Name:</span>
              <span style={{ fontWeight: "600" }}>{data?.userId?.name}</span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Email:</span>
              <span style={{ fontWeight: "600" }}>{data?.userId?.email}</span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Phone Number:</span>
              <span style={{ fontWeight: "600" }}>{data?.userId?.phoneNo}</span>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", gap: "20px", width: "50%" }}>
          {/* <div
          style={{
            width: "100%",
            maxWidth: "90px",
            height: "90px",
            borderRadius: "50%",
          }}
        >
          <img
            src={`${URL}images/${data?.pharmacyId?.logo}`}
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
            alt="Image"
          />
        </div> */}
          <div>
            <MdLocalPharmacy size={50} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <span style={{ fontSize: "20px", fontWeight: "600" }}>
              Pharmacy Details
            </span>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Name:</span>
              <span style={{ fontWeight: "600" }}>
                {data?.pharmacyId?.name}
              </span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Email:</span>
              <span style={{ fontWeight: "600" }}>
                {data?.pharmacyId?.email}
              </span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Phone Number:</span>
              <span style={{ fontWeight: "600" }}>
                {data?.pharmacyId?.phoneNumber}
              </span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Service:</span>
              <span style={{ fontWeight: "600" }}>
                {data?.pharmacyId?.serviceOptions}
              </span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Address:</span>
              <span style={{ fontWeight: "600" }}>
                {data?.pharmacyId?.Address}
              </span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Preffered Payment Method:</span>
              <span style={{ fontWeight: "600" }}>
                {data?.pharmacyId?.preferredPaymentMethod}
              </span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Operating Hours:</span>
              <span style={{ fontWeight: "600" }}>
                {data?.pharmacyId?.OperatingHours}
              </span>
            </div>
          </div>
        </div>
      </div>
      {
        data?.OrderMethod=="Delivery"?
      
      <div
        style={{
          display: "flex",
          gap: "20px",
          width: "100%",
          marginTop: "10px",
        }}
      >
        <div style={{ display: "flex", gap: "20px", width: "100%" }}>
          <div>
            <MdDeliveryDining size={50} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <span style={{ fontSize: "20px", fontWeight: "600" }}>
              Rider Details
            </span>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Name:</span>
              <span style={{ fontWeight: "600" }}>{data?.riderId?.first_name+" "+data?.riderId?.last_name}</span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Email:</span>
              <span style={{ fontWeight: "600" }}>{data?.riderId?.email}</span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Phone Number:</span>
              <span style={{ fontWeight: "600" }}>{data?.riderId?.phone_number}</span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>License Number:</span>
              <span style={{ fontWeight: "600" }}>{data?.riderId?.license_number}</span>
            </div>
          </div>
        </div>
      </div>:null}
      <div
        style={{
          width: "100%",
          borderTop: "1px solid #b4b5c3",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      ></div>

      <div style={{ width: "100%", display: "flex", gap: "20px" }}>
        <div style={{ display: "flex", gap: "20px", width: "70%" }}>
          <div>
            <FaCartArrowDown size={50} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <span style={{ fontSize: "20px", fontWeight: "600" }}>
              Order Details
            </span>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Order No:</span>
              <span style={{ fontWeight: "600" }}>{data?.orderNo}</span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Order Date:</span>
              <span style={{ fontWeight: "600" }}>
                {new Date(data?.createdAt).toLocaleDateString()}
              </span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Order Method:</span>
              <span style={{ fontWeight: "600" }}>{data?.OrderMethod}</span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Order Status:</span>
              <span style={{ fontWeight: "600" }}>{data?.OrderStatus}</span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Availability:</span>
              <span style={{ fontWeight: "600" }}>{data?.availability}</span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Delivery Status:</span>
              <span style={{ fontWeight: "600" }}>{data?.deliveryStatus}</span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Medicine Quantity:</span>
              <span style={{ fontWeight: "600" }}>
                {data?.medicineQuantity}
              </span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Payment Method:</span>
              <span style={{ fontWeight: "600" }}>{data?.paymentMethod}</span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Current Location:</span>
              <span style={{ fontWeight: "600" }}>{data?.currLocation}</span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Comments:</span>
              <span style={{ fontWeight: "600" }}>{data?.comments}</span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Total Price:</span>
              <span style={{ fontWeight: "600" }}>{data?.totalPrice}</span>
            </div>
          </div>
        </div>
        <div style={{ position: "relative", width: "26%" ,}}>
          {
            data?.images?.length>1?
          
          <Slider style={{ padding: "10px 10px 10px 10px" ,}} {...settings}>
            {

            data?.images?.map((item, i) => {
              return (
                <>
                <div  key={i}>
                  <div
                    style={{
                      width: "100%",
                      // maxWidth:"100px",
                      // maxWidth: "90px",
                      height: "100%",
                      // maxHeight: "100px",
                      // borderRadius: "50%",
                    }}
                  >
                    <img
                      src={`${URL}images/${item}`}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      alt="Image"
                    />
                  </div>
                </div>
                </>
              );
            })
            }
          </Slider>
          :
          <div
          style={{
            width: "100%",
            // maxWidth:"100px",
            // maxWidth: "90px",
            height: "100%",
            // maxHeight: "100px",
            // borderRadius: "50%",
          }}
        >
          <img
            src={`${URL}images/${data?.images[0]}`}
            style={{
              width: "100%",
              height: "100%",
            }}
            alt="Image"
          />
        </div>
          }
        </div>
      </div>
    </>
  );
}

export default OrderDetails;
