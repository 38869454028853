import "../Dashboard/style.css";
import "../Dashboard/secondTable.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import URL from "../../../BaseUrl/BaseUrl";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { MdDelete } from "react-icons/md";
import { FiTrash } from "react-icons/fi";

import { MdVisibility } from "react-icons/md";
function RiderTable({ usersData, setUsersData,setshowDetail,setselectedRider }) {
  const [address, setAddress] = useState([]);
  const form = useForm();
  const { control, register, handleSubmit, formState } = form;
  const { errors } = formState;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)

  // all data is stroed in this state

  const [id, setId] = useState([]);

  // for fetching all the data
  const getData = async () => {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    };
    setIsLoading(true)
    axios.get(`${URL}rider/getAll`, { headers: header }).then((res) => {

      setUsersData(res.data?.data)
  }).catch((err) => {
      setIsLoading(false)
  }).finally(err => {
      setIsLoading(false)
  })
    // const res = await axios.get(`${URL}rider/getAll`, { headers: header });
    // const data = res.data.data;

    // setUsersData(data);
  };
  useEffect(() => {
    getData();
  }, []);


  const refreshData = () => {
    getData();
  };
  const fn_handleBlockUser = (data) => {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    setIsLoading(true)

    axios
      .put(`${URL}rider/update/${data?._id}`, { headers: header, status: data?.status==true?false:true })
      .then((res) => {
    setIsLoading(false)
        getData();
        toast.success(res.data.message);
      })
      .catch((err) => {
    setIsLoading(false)
        toast.error(err.response.data.message);
      });
  };
  const handleDelete=(data)=>{
    const header = {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    setIsLoading(true)

    axios
      .delete(`${URL}rider/delete/${data?._id}`, { headers: header })
      .then((res) => {
    setIsLoading(false)

        getData();
        toast.success(res.data.message);
      })
      .catch((err) => {
    setIsLoading(false)

        toast.error(err.response.data.message);
      }); 
  }
  return (
    <section className="section">
      <ToastContainer />
      <TableContainer component={Paper} className="table">
        <div className="d-flex py-2 align-items-center justify-content-between">
          <h3>Riders</h3>
          <div className="input-box">
            <input type="text" className="px-3" placeholder="Search..." />
            <i className="fa-solid fa-search"></i>
          </div>
        </div>
        {isLoading && <div style={{ textAlign: "end" }}><CircularProgress size={35} /></div>}
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: "#F5F5F7" }}>
              <TableCell className="tableHeadCell">#</TableCell>
              <TableCell className="tableHeadCell">Name</TableCell>
              <TableCell className="tableHeadCell">Email</TableCell>
              <TableCell className="tableHeadCell">License Number</TableCell>
              <TableCell className="tableHeadCell">Phone No</TableCell>
              <TableCell className="tableHeadCell">Status</TableCell>
              <TableCell className="tableHeadCell">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersData?.map((usersData, i) => (
              <TableRow key={i} className="tableRow">
                <TableCell className="tableCell">{i + 1}</TableCell>
                <TableCell className="tableCell">
                  {usersData?.first_name + " " + usersData?.last_name}
                </TableCell>
                <TableCell className="tableCell">{usersData?.email}</TableCell>

                <TableCell className="tableCell">
                  {usersData?.license_number}
                </TableCell>
                <TableCell className="tableCell">
                  {usersData?.phone_number}
                </TableCell>
                <TableCell className="tableCell">
                  <label class="switch">
                    <input
                      type="checkbox"
                      value={usersData?.status}
                      checked={usersData?.status}
                      onChange={() => {
                        fn_handleBlockUser(usersData);
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </TableCell>
                <TableCell className="tableCell">
                  <Box sx={{display:"flex"}}>
                    
                  <Tooltip title="Delete">
                    <IconButton onClick={()=>{
                        handleDelete(usersData);
                    }}>
                      <FiTrash style={{ cursor: "pointer", color: "red" }} fontSize={20} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View Detail">
                    <IconButton 
                    onClick={()=>{
                      setselectedRider(usersData)
                      setshowDetail(true);
                    }}
                    >
                      <MdVisibility />
                    </IconButton>
                  </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
}

export default RiderTable;
