import React, { useState, useEffect } from "react";
import List from "./SliderTable";



function NewSlider() {
    return(
        <>
        <List/>
        </>
    )
}

export default NewSlider;
