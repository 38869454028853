import React, { useState } from "react";
import RiderTable from "./RiderTable";
import RiderDetail from "./RiderDetail";

function Rider() {
  const [usersData, setUsersData] = useState([]);
  const [selectedRider, setselectedRider] = useState(null);
  const [showDetail, setshowDetail] = useState(false);

  return (
    <>
      <h5 style={{ padding: "10px" }}>
       {showDetail?"Rider Details":"Rider"} <i class="fa-solid fa-people-group "></i>
      </h5>
      {showDetail ? (
          <div className="row mt-4">
            <RiderDetail setselectedRider={setselectedRider} selectedRider={selectedRider} showDetail={showDetail} setshowDetail={setshowDetail} usersData={usersData} setUsersData={setUsersData} />
          </div>
        ) : 
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="dash6">
              <div className="dash5">
                <div className="dash3">
                  <i
                    class="fa-solid fa-people-group "
                    style={{ backgroundColor: "#ed7016" }}
                  ></i>
                </div>
                <div className="dash4">
                  <p>
                    Rider<br></br>
                    <span style={{ display: "flex", justifyContent: "center" }}>
                      {usersData?.length}
                    </span>
                  </p>
                </div>
              </div>

              <div className="dash1">
                <div className="dash2">
                  <img src={require("../../../assets/images/3.jpeg")} />
                </div>
              </div>
            </div>
          </div>
        </div>
    
          <div className="row mt-4">
            <RiderTable setselectedRider={setselectedRider} setshowDetail={setshowDetail} usersData={usersData} setUsersData={setUsersData} />
          </div>
        
      </div>}
    </>
  );
}

export default Rider;
