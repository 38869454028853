import "../Dashboard/style.css";
import "../Dashboard/secondTable.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import URL from "../../../BaseUrl/BaseUrl";
import { toast, ToastContainer } from "react-toastify";
import { Box, CircularProgress, Typography } from "@mui/material";
import { IoArrowBack } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaPhoneVolume } from "react-icons/fa6";
import { GrLicense } from "react-icons/gr";
function RiderDetail({ selectedRider, setselectedRider, setshowDetail }) {
  // all data is stroed in this state
  const [isLoading, setIsLoading] = useState(false);
  const [data, setdata] = useState(null);
  console.log(data);
  // for fetching all the data
  const getData = async () => {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    };
    setIsLoading(true);
    axios
      .get(`${URL}rider/get/${selectedRider?._id}`, {
        headers: header,
      })
      .then((res) => {
        setIsLoading(false);
        setdata(res.data?.data);
      })
      .catch((err) => {
        setIsLoading(false);
      })
      .finally((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <section className="section">
      <ToastContainer />
      <Box
        onClick={() => {
          setshowDetail(false);
          setselectedRider(null);
        }}
        sx={{
          width: "50px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          backgroundColor: "#116cc9",
          cursor: "pointer",
        }}
      >
        <IoArrowBack color="#fff" size={22} />
      </Box>
      {isLoading && (
        <div
          style={{
            textAlign: "end",
            width: "100%",
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
          }}
        >
          <CircularProgress size={30} />
        </div>
      )}
      <div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center",}}>
      <div
        style={{
          width: "100%",
          maxWidth: "fit-content",
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          gap: "30px",
        }}
      >
        <a href={`${URL}images/${data?.selfie}`}>
        <div
          style={{
            width: "100%",
            maxWidth: "100px",
            height: "100px",
            borderRadius: "50%",
          }}
        >
          <img
            src={`${URL}images/${data?.selfie}`}
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
            alt="Image"
          />
        </div>
        </a>
        <div style={{display:"flex",flexDirection:"column",gap:"7px"}}>
        
          <div style={{ fontWeight: "bold", color: "#000000" ,}}>
            {data?.first_name + " " + data?.last_name}
          </div>
         
          <div style={{ display: "flex", gap: "10px" ,justifyContent:"start",alignItems:"start",}}>
         <span>
            <MdEmail size={18} />
           
            </span>
            <div >
            {data?.email}
            </div>
            
          </div>
          <div style={{ display: "flex", gap: "10px" ,justifyContent:"start",alignItems:"start",maxHeight:"27px"}}>
            <div >
            <FaPhoneVolume size={16} />
            </div>
            <div>
            <div >
            {data?.phone_number}
            </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" ,justifyContent:"start",alignItems:"start",}}>
            <div >
            <GrLicense size={16} />
            </div>
            <div>
            <div >
            {data?.license_number}
            </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div style={{width:"100%",borderTop:"1px solid #b4b5c3"}}></div>
      <div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center",paddingTop:"30px"}}>
      <div style={{display:"flex",flexDirection:"row",gap:"40px",width:"fit-content",flexWrap:"wrap"}}>
      <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontWeight: "bold",color:"#000000" }}>Cnic Front</div>
            <a href={`${URL}images/${data?.cnic_front}`}>
            <div style={{ width: "150px", height: "150px", borderRadius: "50%" }}>
              <img
                src={`${URL}images/${data?.cnic_front}`}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                alt="Image"
              />
            </div>
            </a>
          </div>
      <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontWeight: "bold",color:"#000000" }}>Cnic Back</div>
            <a href={`${URL}images/${data?.cnic_back}`}>
            <div style={{ width: "150px", height: "150px", borderRadius: "50%" }}>
              <img
                src={`${URL}images/${data?.cnic_back}`}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                alt="Image"
              />
            </div>
            </a>
          </div>
      <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontWeight: "bold",color:"#000000" }}>License Image</div>
            <a href={`${URL}images/${data?.license_image}`}>
            <div style={{ width: "150px", height: "150px", borderRadius: "50%" }}>
              <img
                src={`${URL}images/${data?.license_image}`}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                alt="Image"
              />
            </div>
            </a>
          </div>
      </div>
      </div>
      {/* <Box
          sx={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            paddingTop: "50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Selfie</Typography>
            <Box sx={{ width: "150px", height: "150px", borderRadius: "50%" }}>
              <img
                src={`${URL}images/${data?.selfie}`}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                alt="Image"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Cnic Front</Typography>
            <Box sx={{ width: "150px", height: "150px", borderRadius: "50%" }}>
              <img
                src={`${URL}images/${data?.cnic_front}`}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                alt="Image"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Cnic Back</Typography>
            <Box sx={{ width: "150px", height: "150px", borderRadius: "50%" }}>
              <img
                src={`${URL}images/${data?.cnic_back}`}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                alt="Image"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>License Image</Typography>
            <Box sx={{ width: "150px", height: "150px", borderRadius: "50%" }}>
              <img
                src={`${URL}images/${data?.license_image}`}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                alt="Image"
              />
            </Box>
          </Box>
        </Box> */}
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        <Box className={"directionCol"} sx={{ display: "flex", width: "100%" }}>
          <Box
            className={"width100"}
            sx={{ display: "flex", gap: "20px", width: "33%" }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Full Name:</Typography>
            <Typography>{data?.first_name + " " + data?.last_name}</Typography>
          </Box>
          <Box
            className={"width100"}
            sx={{ display: "flex", gap: "20px", width: "33%" }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Email:</Typography>
            <Typography>{data?.email}</Typography>
          </Box>
          <Box
            className={"width100"}
            sx={{ display: "flex", gap: "20px", width: "33%" }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Phone Number:</Typography>
            <Typography>{data?.phone_number}</Typography>
          </Box>
        </Box>
        <Box
          className={"directionCol"}
          sx={{ display: "flex", width: "100%", flexWrap: "wrap" }}
        >
          <Box
            className={"width100"}
            sx={{ display: "flex", gap: "20px", width: "33%" }}
          >
            <Typography sx={{ fontWeight: "bold" }}>License Number:</Typography>
            <Typography>
              { data?.license_number}
            </Typography>
          </Box>
          <Box
            className={"width100"}
            sx={{ display: "flex", gap: "20px", width: "33%" }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Status:</Typography>
            <Typography>
              {data?.status == false ? "Pending" : "Approved"}
            </Typography>
          </Box>
          <Box
            className={"width100"}
            sx={{ display: "flex", gap: "20px", width: "33%" }}
          >
            <Typography sx={{ fontWeight: "bold" }}></Typography>
            <Typography></Typography>
          </Box>
        </Box>
     
      </Box> */}
    </section>
  );
}

export default RiderDetail;
