import "../Dashboard/style.css";
import "../Dashboard/secondTable.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import URL from "../../../BaseUrl/BaseUrl";
import Swiper from '../../../components/Swiper'
import { useState } from "react";
import { BiSolidWallet } from "react-icons/bi";
import axios from "axios";
import { Box } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
const List = ({ pharmacy }) => {
  const form = useForm()
  const { control, register, handleSubmit, formState,reset  } = form
  const { errors } = formState
  const [images, setImages] = useState([])
  const [selectedPharmacy, setselectedPharmacy] = useState(null)
  const [test, setTest] = useState("")
  async function fn_handleChangeStatus(e, id) {
    const value = e.target.value
    const header = {
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'

    }

    if (value?.toString().length > 0) {

      await axios.post(`${URL}pharmacy/adminPanel/update/${id}`, {
        status: value === 'true' ? "Approved" : "Pending",
        id,
      }, { headers: header })
    }
  }
  console.log(selectedPharmacy);
  const onSubmit = (data) => {
console.log(data);
    const header = {
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      'Accept': 'application/json',
      'Content-Type': "application/json"

    }
    axios.post(`${URL}pharmacy/addAmountToPharmacyWallet`, {...data,pharmacyId:selectedPharmacy?._id}, { headers: header }).then((res) => {
      if (res?.status === 200) {
        reset();
        toast.success("Create Successfully")
          setTest("modal")
        const userAdd = document.getElementById("userAdd");
        setselectedPharmacy(null)
        // userAdd?.addEventListener("click", function () {
        //   setTest("modal")
        // })
      }else{
        toast.error(res.data.message)

      }
    }).catch((err) => {
      toast.error(err.response.data.message)

      // console.log(err);
    })
  }

  return (
    <section className="section">
      <ToastContainer />

      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: "#F5F5F7" }}>
              <TableCell className="tableHeadCell">#</TableCell>
              <TableCell className="tableHeadCell">Pharmacy Name</TableCell>
              <TableCell className="tableHeadCell">City</TableCell>
              <TableCell className="tableHeadCell">Location</TableCell>
              <TableCell className="tableHeadCell">Opreating Hours</TableCell>
              <TableCell className="tableHeadCell">Payment Methood</TableCell>
              <TableCell className="tableHeadCell">Legal Documents</TableCell>
              <TableCell className="tableHeadCell">Commision</TableCell>
              <TableCell className="tableHeadCell">Status</TableCell>
              <TableCell className="tableHeadCell">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pharmacy?.map((pharmacy, i) => (
              <TableRow key={i} className="tableRow">
                <TableCell className="tableCell">{i + 1}</TableCell>
                <TableCell className="tableCell">{pharmacy.name}</TableCell>
                <TableCell className="tableCell">{pharmacy.City}</TableCell>
                <TableCell className="tableCell">loaction </TableCell>
                <TableCell className="tableCell">{pharmacy.OperatingHours}</TableCell>
                <TableCell className="tableCell">{pharmacy.preferredPaymentMethod}</TableCell>
                <TableCell className="tableCell">
                  {pharmacy?.pharmacyLegalDocumentImages?.length > 0 ? <span style={{ cursor: "pointer" }} onClick={() => setImages(pharmacy?.pharmacyLegalDocumentImages)}>
                    <i className="fa-solid fa-file" style={{ fontSize: '25px', color: "blue", textDecoration: 'none', margin: '0px 5px' }}>

                    </i>
                  </span> : '---'}
                  {/* {
                  pharmacy?.pharmacyLegalDocumentImages?.map((img) => {
                    return (
                      <>
                        <a href={`${URL}images/${img}`} className="text-decoration-none"> <i className="fa-solid fa-file" style={{ fontSize: '25px', textDecoration: 'none', margin: '0px 5px' }}></i></a>
                      </>
                    )
                  })
                } */}
                </TableCell>

                <TableCell className="tableCell">{pharmacy.commissionPercentage}</TableCell>
                <TableCell className="tableCell">{pharmacy.status}</TableCell>
                <TableCell className="tableCell">
                  <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                  {/* <button 
                  // onClick={()=>{
                  //   setselectedPharmacy(pharmacy)
                  // }} 
                  data-bs-toggle="modal" data-bs-target="#pharmacyWalletModal" type="button" className="info-button my-btn" > */}
                  <BiSolidWallet 
                  onClick={(e)=>{
                    e.stopPropagation()
                    setselectedPharmacy(pharmacy)
                  }} 
                  style={{zIndex:57654}}
                   data-bs-toggle="modal" data-bs-target="#pharmacyWalletModal" type="button" size={22}/>

            {/* </button> */}
                  <select style={{
                    border: "1px solid rgba(0,0,0,0.2)", padding: "0.3rem"
                  }} onChange={(e) => fn_handleChangeStatus(e, pharmacy._id)}>
                    <option selected disabled>--Status--</option>
                    <option value={true} >Approved</option>
                    <option value={false}>Pending</option>
                  </select>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer >
      {
        images.length > 0 ? <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100vh", background: "rgba(0,0,0,0.1)", zIndex: 20 }}>
          <span style={{ cursor: "pointer", zIndex: 21 }} onClick={() => setImages([])}><i className="fa-solid fa-close" style={{ fontSize: '30px', color: "red", position: "absolute", top: 20, right: 20 }}>
          </i></span>
          <Swiper imagesArr={images} />
        </div> : null
      }
      {/* Add Wallet Modal  */}
      <div
              className="modal fade"
              id="pharmacyWalletModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{ marginTop: '-90px' }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      <div className="d-flex align-items-center">
                        <p className="ms-3 mb-0">Add Pharmacy wallet</p>
                      </div>
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="container">
                        <div className="row justify-content-between">
                          <div className="col-md-12">
                            <input
                              type="number"
                              className="form-control"
                              id="amount"
                              aria-describedby="emailHelp"
                              placeholder="Amount"
                              {...register('amount', {
                                required: {
                                  value: true,
                                  message: 'this field is required'
                                }
                              })}

                            />
                            <small>{errors.amount?.message}</small>

                          </div>

                        </div>

                      </div>

                      <div className="mt-5 ms-2">
                        <button type="submit" id="userAdd" data-bs-dismiss={test} className="btn btn-danger text-white">Create</button>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

    </section >
  );
};

export default List;
